import React from "react"
import LayoutComponent from "../../components/Layout.js"
import Nuklearmedicin from "../../components/Nuklearmedicin.js"
import Knap from "../../components/Knap.js"

const NuklearmedicinPage = () => (
    <LayoutComponent>
        <h1>Nuklearmedicin</h1>
        <p>Nu om dage kan man ikke længere sige røntgentilbehør eller røntgenbeskyttelse uden også at nævne nuklearmedicin. Hertil har vi knyttet vores mangeårige erfaring med salg af røntgentilbehør til også at inkludere røntgenbeskyttelse i form af blybeskyttelse, blyafdækning, røntgenforklæder, blyforklæder, røntgenbriller og blyskjolde, samt træningsfantomer, kvalitetskontrol (QA), patientsimulatorer og vævssimulatorer til nuklearmedicin.</p>
        <h1>Vores leverandører</h1>
        <Nuklearmedicin />
        <Knap />
    </LayoutComponent>
)
export default NuklearmedicinPage